import "../styles/work.css";
import "react-html5video/dist/styles.css";
import { useNavigate } from "react-router-dom";

import Videos from "./videos";

function WorkSection() {
  let navigate = useNavigate();

  return (
    <div className="video-section">
      <h1 className="section-header">Our Work</h1>
      <br />
      <div className="vid-area">
        <div className="videos">
          <Videos
            src="videos/video1.png"
            text="৭ই মার্চ"
            path="/7th-march"
            det="স্বাধীনতার ডাক"
          />
          <Videos
            src="videos/video2.png"
            text="Shoishob Fashion"
            path="/shoishob"
            det="Baby goods/Childrens goods"
          />
          <Videos
            src="videos/video3.png"
            text="Toyo Auto"
            path="/toyoauto"
            det="Drive your Desire"
          />
        </div>
        <br />
        <div className="view-more">
          <button
            onClick={() => {
              navigate("/work");
            }}
            type="button"
            class="btn-view-more"
            style={{ alignItems: "center" }}
          >
            See More
          </button>
        </div>
      </div>
    </div>
  );
}

export default WorkSection;
