import React from 'react'
import '../styles/main.css';
import Header from '../assets/images/contact.jpg';
import ContactForm from '../components/contactFrom';
import { TabTitle } from '../components/titles';
import MessengerCustomerChat from 'react-messenger-customer-chat';

function Contact () {
    TabTitle('Contact Us')
    return (
        <div className='contact-page'>
            <div className='contact'>
                <img src = {Header} className="header-img" alt="header"/>
                <h1 className='contact-header'>Contact Us</h1>   
            </div>
            <div className='card'>
                <div className='contact-section'>
                <ContactForm/>
                <MessengerCustomerChat
                pageId="109285541824328"
                appId="628708905345763"
            />,
            </div>
            </div>
        </div>
    )
}

export default Contact;