import React from "react";
import {HashRouter, Route, Routes } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Home from "./routes/home";
import About from "./routes/about";
import Services from "./routes/services";
import Work from "./routes/work";
import Error from "./routes/error";
import Contact from "./routes/contact";
import Navbar from "./components/navbar";
import Footer from "./components/footer";
import WorkPage from "./components/works/7th-march";
import Shoishob from "./components/works/shoishob";
import ToyoAuto from "./components/works/toyoauto";

function App() {
  return (
    <div className="App">
      <HashRouter>
        <Navbar />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/services" element={<Services />} />
          <Route path="/work" element={<Work />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="*" element={<Error />} />
          <Route path="/7th-march" element={<WorkPage />} />
          <Route path="/shoishob" element={<Shoishob />} />
          <Route path="/toyoauto" element={<ToyoAuto />} />
          </Routes>
        <Footer />
      </HashRouter>
    </div>
  );
}

export default App;
