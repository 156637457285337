import React, { Component } from "react";
import Slider from "react-slick";
import "../styles/clientSection.css";


export default class SwipeToSlide extends Component {
  render() {
    const settings = {
      speed: 500,
      autoplay: true,
      slidesToShow: 5,
      slidesToScroll: 1,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 5,
            slidesToScroll: 5,
            infinite: true,
            dots: true,
          },
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
            initialSlide: 2,
          },
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
          },
        },
      ],
    };

    return (
      <div className="home-section">
        <div className="logo-section">
          <h1 className="client-header">Our Clients</h1>

          <div className="logo-area">
            <Slider {...settings}>
              <div className="card-logo">
                <img className="logo-icon" src="logo/bproperty.png" alt="" />
              </div>
              <div className="card-logo">
                <img className="logo-icon" src="logo/Hatil.png" alt="" />
              </div>
              <div className="card-logo">
                <img className="logo-icon" src="logo/jamuna.png" alt="" />
              </div>
              <div className="card-logo">
                <img className="logo-icon" src="logo/navana.png" alt="" />
              </div>
              <div className="card-logo">
                <img className="logo-icon" src="logo/samsung.png" alt="" />
              </div>
              <div className="card-logo">
                <img className="logo-icon" src="logo/shoishob.png" alt="" />
              </div>
              <div className="card-logo">
                <img className="logo-icon" src="logo/toyoauto.png" alt="" />
              </div>
              <div className="card-logo">
                <img className="logo-icon" src="logo/Twelve.png" alt="" />
              </div>
              <div className="card-logo">
                <img className="logo-icon" src="logo/urbanedge.png" alt="" />
              </div>
              <div className="card-logo">
                <img className="logo-icon" src="logo/achoura.png" alt="" />
              </div>
            </Slider>
          </div>
        </div>
      </div>
    );
  }
}
