import React from 'react';
import '../styles/footer.css';
import logowhite from '../assets/logos/logo-white.svg'
import { Link } from 'react-router-dom';

function Footer() {
  return (
    <div className='footer-container'>
      <div className='footer-logo'>
        <div className='footer-logo-heading'>
        <img src={logowhite} width="120"  alt="logo"/>
        </div>
        <div className='social-media'>
            <a
              className='social-icon-link facebook'
              href='https://www.facebook.com/spadeadigital'
              target='_blank'
              aria-label='Facebook'
            >
              <i className='fab fa-facebook-f' />
            </a>
            <a
              className='social-icon-link instagram'
              href='https://www.instagram.com/spadea.digital/'
              target='_blank'
              aria-label='Instagram'
            >
              <i className='fab fa-instagram' />
            </a>
            <a
              className='social-icon-link youtube'
              href='https://www.youtube.com/channel/UCnSPqJ_i4g6E_XqbB7Xz2uQ'
              target='_blank'
              aria-label='Youtube'
            >
              <i className='fab fa-youtube' />
            </a>
            {/* <a
              className='social-icon-link twitter'
              href='https://www.facebook.com/spadeadigital'
              target='_blank'
              aria-label='Twitter'
            >
              <i className='fab fa-twitter' />
            </a> */}
            <a
              className='social-icon-link linkedin'
              href='https://www.linkedin.com/company/spadea-digital/'
              target='_blank'
              aria-label='LinkedIn'
            >
              <i className='fab fa-linkedin' />
            </a>
        </div>
        
      </div>
      <div className='footer-info'>
        <i class="fas fa-map-marker-alt"/> 	&nbsp; Level 1, House 188, Road 8, Block C, Bashundhara R/A, 1229 Dhaka, Bangladesh.
      </div>
      <div className='footer-info-address'>
        <i class="fas fa-phone-alt"/>	&nbsp;01936-800976
      </div>
      <div className='footer-info-address'>
        <i class="fas fa-envelope"/>	&nbsp; info@spadeadigital.com
      </div>
      
      <div className='footer-end'>
        <div className='footer-end-rights'>
          <span>©2022 <b>Spadea Digital</b>. All rights reserved.</span>
        </div>
      </div>
    </div>
  );
}

export default Footer;
