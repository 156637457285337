import React from "react";
import "../styles/about.css";
import Header from "../assets/images/about.jpg";
import { TabTitle } from "../components/titles";

function About() {
  TabTitle("About Us");
  return (
    <div className="about-page">
      <div className="about">
        <img src={Header} className="header-img" alt="header" />
        <h1 className="about-header">About Us</h1>
      </div>

      <div className="cards">
        <div className="about-text">
          <h1>
            {" "}
            Spadea Digital is an IT, marketing and content creation company that
            uses original organic material to assist clients in creating
            cohesive experiences. We build significant solutions across a range
            of platforms, be they physical, digital, or social, with a heavy
            focus on good creativity and a solid foundation in technology,
            analytics, and strategy. An association with the audience that is
            considerably more dependable, worthwhile, and powerful is produced
            by this integrated brand experience.
          </h1>
          <br />
          <div className="about-cat">
            <h1>Our Goal</h1>
            <br />
            <p>
              In a constantly changing environment, continue to grow and develop
              while offering comprehensive solutions to your communication
              demands.
            </p>
            <br />
            <h1>What We Look At</h1>
            <br />
            <p>
              To foster an environment at work that encourages curiosity,
              hunger, and motivation so that we can come up with remarkable
              ideas that others want to spend time discussing.
            </p>
          </div>

          {/* <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</p> */}
          {/* <div className='about-cat'>
                    <h1>Our Team Leads</h1>
                    <br/>
                    
                    <React.Fragment>
                        <div className="container">
                            <br/>
                            <div className="row">
                                <div className="col-md col-sm">

                                    <div className='our-team'>

                                        <div className='our-pic'>
                                            <img src='images/founders/ashik.jpg' alt='team-member' className='img-fluid' width='80%'/>
                                        </div>

                                        <div className='content'>

                                            <h3 className='title'>Kamrul Islam Ashik</h3>

                                            <span className='post'>Dexter</span>
                                            
                                            <ul className='social-icons'>
                                                <li><a  className='social-icon-link facebook' href="https://www.facebook.com/ikamrulashik/" target="_blank" aria-label='Facebook'> <i className='fab fa-facebook-f' /></a></li>
                                                <li><a  className='social-icon-link instagram 'href='https://www.instagram.com/ikamrulashik/' target='_blank' aria-label='Instagram'> <i className='fab fa-instagram' /></a></li>
                                                <li><a  className='social-icon-link linkedin' href='https://www.linkedin.com/in/md-kamrul-islam-asik-70196315a/' target='_blank' aria-label='LinkedIn'> <i className='fab fa-linkedin' /></a></li>
                                            </ul>
                                        </div>

                                    </div>
                                </div>

                                <div className="col-md col-sm">
                                    <div className='our-team'>
                                        <div className='our-pic'>
                                            <img src='images/founders/emon.jpg' alt='team-member' className='img-fluid'/>
                                        </div>
                                        <div className='content'>
                                            <h3 className='title'>Ashraful Alam</h3>
                                            <span className='post'>Iron Man</span>
                                            <ul className='social-icons'>
                                            <li><a  className='social-icon-link facebook' href="https://www.facebook.com/profile.php?id=100010115850575" target="_blank" aria-label='Facebook'> <i className='fab fa-facebook-f' /></a></li>
                                                <li><a  className='social-icon-link instagram 'href='https://www.instagram.com/ashraaf_emn/' target='_blank' aria-label='Instagram'> <i className='fab fa-instagram' /></a></li>
                                                <li><a  className='social-icon-link linkedin' href='https://www.linkedin.com/in/ashraful-alam-ba9199182/' target='_blank' aria-label='LinkedIn'> <i className='fab fa-linkedin' /></a></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-md col-sm">
                                    <div className='our-team'>
                                        <div className='our-pic'>
                                            <img src='images/founders/mishu.jpg' alt='team-member' className='img-fluid'/>
                                        </div>
                                        <div className='content'>
                                            <h3 className='title'>Al Arafat Mishu</h3>
                                            <span className='post'>Nick Fury</span>
                                            <ul className='social-icons'>
                                            <li><a  className='social-icon-link facebook' href="https://www.facebook.com/alarafat.mishu/" target="_blank" aria-label='Facebook'> <i className='fab fa-facebook-f' /></a></li>
                                                <li><a  className='social-icon-link instagram 'href='https://www.instagram.com/mishualarafat/' target='_blank' aria-label='Instagram'> <i className='fab fa-instagram' /></a></li>
                                                <li><a  className='social-icon-link linkedin' href='https://www.linkedin.com/in/arafatmishu/' target='_blank' aria-label='LinkedIn'> <i className='fab fa-linkedin' /></a></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-md col-sm">
                                    <div className='our-team'>
                                        <div className='our-pic'>
                                            <img src='images/founders/abid.jpg' alt='team-member' className='img-fluid'/>
                                        </div>
                                        <div className='content'>
                                            <h3 className='title'>Abid Hossain</h3>
                                            <span className='post'>Megamind</span>
                                            <ul className='social-icons'>
                                            <li><a  className='social-icon-link facebook' href="https://www.facebook.com/djabidgarage/" target="_blank" aria-label='Facebook'> <i className='fab fa-facebook-f' /></a></li>
                                                <li><a  className='social-icon-link instagram 'href='https://www.instagram.com/_abid._.hos.sain_/' target='_blank' aria-label='Instagram'> <i className='fab fa-instagram' /></a></li>
                                                <li><a  className='social-icon-link linkedin' href='https://www.linkedin.com/in/abid-hossain-ansary/' target='_blank' aria-label='LinkedIn'> <i className='fab fa-linkedin' /></a></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-md col-sm">
                                    <div className='our-team'>
                                        <div className='our-pic'>
                                            <img src='images/founders/sameer.jpg' alt='team-member' className='img-fluid'/>
                                        </div>
                                        <div className='content'>
                                            <h3 className='title'>Shadman Sameer</h3>
                                            <span className='post'>Thor</span>
                                            <ul className='social-icons'>
                                                <li><a  className='social-icon-link facebook' href="https://www.facebook.com/shadmansameer007" target="_blank" aria-label='Facebook'> <i className='fab fa-facebook-f' /></a></li>
                                                <li><a  className='social-icon-link instagram 'href='https://www.instagram.com/djsameer_official/' target='_blank' aria-label='Instagram'> <i className='fab fa-instagram' /></a></li>
                                                <li><a  className='social-icon-link linkedin' href='https://www.linkedin.com/in/shadman-sameer-7911201a6/' target='_blank' aria-label='LinkedIn'> <i className='fab fa-linkedin' /></a></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </React.Fragment>


                    </div> */}
        </div>
      </div>
    </div>
  );
}

export default About;
