import React, {useState, useEffect}from 'react'
import {SendEmail } from './api';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import '../styles/contactForm.css';
import InlineError from './inlineError';
import {validateUser, validateEmail, validateText, validatePhone,} from './validation';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function ContactForm () {
    const [user, setUser] = useState("")
    const [email, setEmail] = useState("")
    const [phone, setPhone] = useState()
    const [text, setText] = useState("")
    const [userError, setUserError] = useState()
    const [emailError, setEmailError] = useState()
    const [phoneError, setPhoneError] = useState()
    const [textError, setTextError] = useState()
    const [buttonLoading, setButtonLoading] = useState(false);
    const [send, setSend] = useState();

    useEffect(() => {
        validateUser({ user, setUserError });
        validateEmail({ email, setEmailError });
        validatePhone({ phone, setPhoneError });
        validateText({ text, setTextError });

    if (send) {
        toast.success(send.msg);
        setUser("")
        setEmail("")
        setText("")
        setPhone("")
        setSend()
      }
    }, [user, email, phone, text, send]);
  
    const submitHandler = (e) => {
      e.preventDefault();
      setButtonLoading(true);
      if (!userError & !emailError & !phoneError & !textError) {
        SendEmail({ user, email, text,phone, setSend })
        .then(() => {
            setButtonLoading(false);
          }
        );
      }
    };

    return (
        <Container>
            <ToastContainer />
            <Row>
                <Col>
                    <div className='contact-sidebar'>
                        <div className='visit-us'>
                            <div className='visit-us-header'>
                                <h1>Visit Us</h1>
                            </div>
                            <br/>
                            <div className='visit-location'>
                                <i className="fas fa-map-marker-alt"/> 	&nbsp; Level 1, House 188, Road 8, Block C, Bashundhara R/A <br/>
                                Dhaka, Bangladesh.
                            </div>
                        </div>
                        <br/>
                        <div className='contact-us'>
                            <div className='contact-us-header'>
                                <h1>Contact Us</h1>
                            </div>
                            <br/>
                            <div className='contact-phone'>
                                <i className="fas fa-phone-alt"/>	&nbsp;01936-800976
                            </div>
                            <div className='contact-mail'>
                                <i className="fas fa-envelope"/>	&nbsp;info@spadeadigital.com
                            </div>
                        </div>
                    </div>
                </Col>
                <Col>
                    <div className='contact-form'>
                        
                            <form onSubmit={submitHandler} className="box-2 bg-white pt-12 pb-6 sm:px-12 px-6">
                            <div className="mb-3">
                                <input type="name" className="form-control" placeholder="Your Name" value={user} onChange={(e)=> setUser(e.target.value)} required/>
                                {userError && <InlineError error={userError} />}
                            </div>
                            <div className="mb-3">
                                <input type="email" className="form-control" id="exampleFormControlInput1" placeholder="Email Address" value={email} onChange={(e)=> setEmail(e.target.value)} required/>
                                {emailError && <InlineError error={emailError} />}
                            </div>
                            
                            <div className="mb-3">
                                <input type="phone" className="form-control" placeholder="Phone Number" value={phone} onChange={(e)=> setPhone(e.target.value)} required/>
                                {phoneError && <InlineError error={phoneError} />}
                            </div>
                            
                            <div className="mb-3">
                                <textarea className="form-control" rows="3" placeholder="Your Message" value={text} onChange={(e)=> setText(e.target.value)} required/>
                                {textError && <InlineError error={textError} />}
                            </div>
                            <div className='btn-message'>
                                <button type="submit" disabled={buttonLoading && true} className="btn-send">{buttonLoading ? 'Loading..' :'Send Message'}</button>
                            </div>
                        </form>
                    </div>
                </Col>
            </Row>
        </Container>
    );
}

export default ContactForm;