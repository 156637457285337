const validateUser = ({ user, setUserError }) => {
  return user && user.length < 5
    ? setUserError('Your name is too short')
    : user && user.length > 50
    ? setUserError('Try to make short and meanfull')
    : setUserError('');
};

const validateEmail = ({email, setEmailError}) => {
    const emailRegular =
      /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return email && !email.match(emailRegular)
      ? setEmailError('Email not valid')
      : setEmailError('');
  };
  
  const validatePhone = ({phone, setPhoneError}) => {
    var phoneRegular = /^\(?([0-9]{11})$/;
    return phone && !phone.match(phoneRegular)
      ? setPhoneError('Phone Number not valid')
      : setPhoneError('');
  };
  
  const validateText = ({text, setTextError}) => {
    return text && text.length < 5
      ? setTextError('Message is too short')
      : text && text.length > 200
      ? setTextError('Try to make short and meanfull')
      : setTextError('');
  };
  
  export {validateUser, validateEmail, validatePhone, validateText};