import React from 'react';
import "../styles/work.css"
import 'react-html5video/dist/styles.css'
import Videos from '../components/videos'



function VideoGallery() {

  return (
    <>
    
      <div className='videos'> 
        
      <Videos
                  src='videos/video1.png'
                  text='৭ই মার্চ'
                  path='/7th-march'
                  det='স্বাধীনতার ডাক'
                />
                <Videos
                  src='videos/video2.png'
                  text='Shoishob'
                  path='/shoishob'
                  det='Baby goods/Childrens goods'
                />
                <Videos
                  src='videos/video3.png'
                  text='Toyo Auto'
                  path='/toyoauto'
                  det='Drive your Desire'
                />
          </div>
    
        
    </>
  );
}

export default VideoGallery;