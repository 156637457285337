import React from "react";
import { useNavigate } from "react-router-dom";
import "../styles/main.css";
import { TabTitle } from "../components/titles";

function Error() {
  TabTitle("Error");
  let navigate = useNavigate();
  return (
    <div className="error-page">
      <div className="cards">
        <h1 className="error-header"> 404 Error</h1>
        <h1 style={{ fontSize: 115 }}>Nothing to see here!</h1>
        <br />
        <div className="content-error">
          <p style={{ textAlign: "center", fontSize: 40 }}>
            We are really sorry but the page you requested is missing!
          </p>
          <button
            onClick={() => {
              navigate("/");
            }}
            type="button"
            class=" btn-get-back"
            style={{ alignItems: "center" }}
          >
            Go Back
          </button>
        </div>
        <br />
      </div>
    </div>
  );
}

export default Error;
