import React from "react";
import "../styles/main.css";
import Container from "../components/container";
import ServiceSection from "../components/serviceSection";
import ClientSection from "../components/clientSection";
import WorkSection from "../components/workSection";
import { TabTitle } from "../components/titles";

function Home() {
  TabTitle("Spadea Digital");
  return (
    <>
      <Container />
      <ServiceSection />
      <WorkSection />
      <ClientSection />
    </>
  );
}

export default Home;
