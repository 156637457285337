import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "react-html5video/dist/styles.css";
import "../../styles/workpage.css";
import { Container } from "react-bootstrap";
import { TabTitle } from "../titles";

function WorkPage() {
  TabTitle("7th March");
  return (
    <div className="cards">
      <Container>
        <div className="embed-responsive embed-responsive-16by9">
          <iframe
            className="embed-responsive-item"
            src="https://www.youtube.com/embed/iYjOgLqwg24"
            allowfullscreen
          ></iframe>
        </div>
      </Container>
    </div>
  );
}

export default WorkPage;
