import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import Offcanvas from "react-bootstrap/Offcanvas";
import logo from "../assets/logos/logo.svg";
import "../styles/navbar.css";
<link href="https://css.gg/border-style-solid.css" rel="stylesheet"></link>;

export const OffcanvasExample = () => {
  return (
    <>
      {["md"].map((expand) => (
        <Navbar
          key={expand}
          bg="light"
          expand={expand}
          className="mb-0"
          fixed="top"
        >
          <Container fluid>
            <Navbar.Brand href="#" className="nav-logo">
              <a href="/">
                <img src={logo} width="100" height="35" alt="logo" />
              </a>
            </Navbar.Brand>
            <Navbar.Toggle aria-controls={`offcanvasNavbar-expand-${expand}`} />
            <Navbar.Offcanvas
              id={`offcanvasNavbar-expand-${expand}`}
              aria-labelledby={`offcanvasNavbarLabel-expand-${expand}`}
              placement="end"
            >
              <Offcanvas.Header closeButton>
                <Offcanvas.Title id={`offcanvasNavbarLabel-expand-${expand}`}>
                  <a href="/">
                    <img src={logo} width="80" height="35" alt="logo" />
                  </a>
                </Offcanvas.Title>
              </Offcanvas.Header>
              <Offcanvas.Body>
                <Nav className="justify-content-end flex-grow-1 pb-2 nav-link sub-nav">
                  <Nav.Link href="#/">Home</Nav.Link>
                  <Nav.Link href="#/about">About Us</Nav.Link>
                  <Nav.Link href="#/services">Services</Nav.Link>
                  <Nav.Link href="#/work">Our Work</Nav.Link>
                  <Nav.Link href="#/contact">Contact Us</Nav.Link>
                </Nav>
              </Offcanvas.Body>
            </Navbar.Offcanvas>
          </Container>
        </Navbar>
      ))}
    </>
  );
};
export default OffcanvasExample;
