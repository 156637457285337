import React from 'react'
import '../styles/services.css';
import Header from '../assets/images/service.jpg';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import { TabTitle } from '../components/titles';

function Services () {
    TabTitle('Services')
    return (
        <div className='service-page'>
            
            <div className='services'>
                <img src = {Header} className="header-img" alt="header"/>
                <h1 className='services-header'>Our Services</h1>
            </div>
            <div className='cards'>
                <div className='services-section'>
                
                <Container>
                    <Row>
                            <div className='service-name'>
                                <h1>Digital Services</h1>
                            </div>
                            <br/>
                        <Col>
                            <div className='ser-img'>
                                <img src="images/marketing.svg" className='img-fluid animated' alt='service'/>
                            </div>
                        </Col>
                        <Col>
                        <br/>
                            <div className='service-text'>
                                <div className='sub-cat'>
                                    <h4>Search Engine Optimization (SEO)</h4>
                                    <hr/>
                                    Our SEO services are aimed at increasing your website's overall effectiveness and relevance for both
                                    users and search engines. To help your website rank higher on search engine results pages (SERPs) for
                                    essential keywords, we will strive to optimize its content, structure, and other technical elements. To
                                    illustrate your site's authority and importance to search engines, we will also generate higher backlinks.
                                </div>
                                <br/>
                                <div className='sub-cat'>
                                    <h4>Social Media Marketing</h4>
                                    <hr/>
                                    With the assistance of our SMM services we will assist you in creating and sustaining a strong online
                                    presence on networks that are useful to your intended audience. Together, you and our team of
                                    specialists will create a plan that will enable you to accomplish your unique marketing objectives. A
                                    community surrounding your business may be built by doing things like producing and sharing highquality content, launching targeted advertising campaigns, and interacting with your audience.
                                </div>
                                {/* <br/>
                                <div className='sub-cat'>
                                    <h4>Content Marketing</h4>
                                    <hr/>
                                    With the assistance of our SMM services we will assist you in creating and sustaining a strong online
                                    presence on networks that are useful to your intended audience. Together, you and our team of
                                    specialists will create a plan that will enable you to accomplish your unique marketing objectives. A
                                    community surrounding your business may be built by doing things like producing and sharing highquality content, launching targeted advertising campaigns, and interacting with your audience.
                                </div> */}
                                <br/>
                                <div className='sub-cat'>
                                    <h4>Email Marketing</h4>
                                    <hr/>
                                    We provide full-service email marketing solutions, including strategy, design, content generation,
                                    automation, and analysis. Our team will collaborate with you to create a tailored email marketing
                                    strategy to accomplish your unique objectives. To find out how our email marketing services can expand
                                    your business.
                                </div>
                                <br/>
                                
                                
                                    
                            
                            </div>
                            <br/>
                        </Col>
                    </Row>
                    <hr/>
                    <br/>
                    <Row>
                        <div className='service-name'>
                                <h1>Production Busket</h1>
                        </div>
                        <Col>
                        <br/>
                            <div className='service-text'>
                                <div className='sub-cat'>
                                    <h4>TVC & OVC</h4>
                                    <hr/>
                                    Our team strive hard to produce videos of the highest caliber while also firmly sticking to budget
                                    pressures. In order to consistently satisfy our clients, we go above and beyond, when necessary, without
                                    compromising the quality of our work. You may feel confident knowing that every step of production,
                                    from screenwriting to editing, will be handled professionally with top-notch outcomes guaranteed. 
                                </div>
                                <br/>
                                <div className='sub-cat'>
                                    <h4>Animation</h4>
                                    <hr/>
                                    Our team concentrates on creating top-notch motion graphics, character design, 2D and 3D animations,
                                    and more. Whether you're looking for something simple or detailed, our skilled animators will
                                    collaborate with you at every stage until your idea is implemented. Our aim is to produce animations
                                    that not only look stunning but also successfully convey the information they are intended to. So that
                                    our clients may effectively reach their target audience.
                                    </div>
                                <br/>
                                <div className='sub-cat'>
                                    <h4>Music Production</h4>
                                    <hr/>
                                    We make sure that your production sounds polished and professional from beginning to end. Our aim is
                                    to assist you in realizing your creative vision while ensuring that it can compete with any other
                                    professionally produced tracks currently on the market. Our crew is capable of recording voices or
                                    instruments, editing tracks, mixing down tunes, and mastering them. At Spadea, we take great care to
                                    pay attention to every detail since we know how crucial each stage is to producing tracks with superb
                                    sound. 
                                </div>
                                
                            </div>
                            <br/>
                        </Col>
                        <Col>
                            <div className='ser-img'>
                                <img src="images/video.svg"  className='img-fluid animated' alt='service'/>
                            </div>
                        </Col>
                    </Row>
                    <hr/>
                    <br/>
                    <Row>
                        <div className='service-name'>
                            <h1>Tech Support</h1>
                        </div>
                        <br/>
                        <Col>
                            <div className='ser-img'>
                                <img src="images/tech.svg" className='img-fluid animated' alt='service'/>
                            </div>
                        </Col>
                        <Col>
                        <br/>
                        <div className='service-text'>
                                <div className='sub-cat'>
                                    <h4>Web Application</h4>
                                    <hr/>
                                    Creating ensures your website design seems professional is crucial. This calls for the use of innovative,
                                    eye-friendly fonts, colors, and images. Also, think about including interactive elements like hover effects
                                    or animations. These may help visitors stay on your site longer and may even influence them to make a
                                    purchase choice in the future. Include relevant keywords in all text-based material (e-books, blog posts,
                                    etc.) for SEO purposes so that search engines can index sites more efficiently.
 
                                </div>
                                <br/>
                                <div className='sub-cat'>
                                    <h4>Mobile Application</h4>
                                    <hr/>
                                    Highlighting the special qualities and benefits that your particular product or service offers. Describe
                                    how these characteristics make it stand out from rivals and why users would profit from using them.
                                    Provide technical information, but also describe how it will enhance the user experience with the app,
                                    such as through faster performance or easier navigation, and any additional services like customer
                                    support choices available through the platform itself. 
                                </div>
                                <br/>
                                <div className='sub-cat'>
                                    <h4>E-commerce Solution</h4>
                                    <hr/>
                                    In order to make getting started as easy as possible, our E-Commerce Solution offers safe hosting with
                                    fast performance. With our user-friendly interface, managing orders, items, payments, and more. We
                                    also provide tools like marketing automation technologies that make it simple for companies to market
                                    their goods through email campaigns or social media posts without any prior knowledge of digital
                                    marketing techniques.
                                </div>
                                
                            </div>
                            <br/>
                        </Col>
                    </Row>
                    <hr/>
                    
                    <br/>
                    <Row>
                        <div className='service-name'>
                                <h1>Events & Activation</h1>
                        </div>
                        
                        <Col>
                            <div className='service-text'>
                                <div className='sub-cat'>
                                    <h4>Corporate Events</h4>
                                    <hr/>
                                    We provide a wide range of services, such as venue selection, food, entertainment reservations, and
                                    more. You can be certain that your corporate event will influence everyone who comes thanks to our
                                    knowledge and attention to detail. We take pride in knowing our clients' unique requirements to give
                                    their guests or staff an outstanding experience that enhances their brand or company and provides
                                    them beneficial networking chances during the duration of their events.

                                </div>
                                <br/>
                                {/* <div className='sub-cat'>
                                    <h4>Audio & Video Equipment</h4>
                                    <hr/>
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce dignissim, elit ac semper dapibus, leo erat dignissim urna, at dictum nunc erat eu mauris. Donec rutrum urna id tortor maximus, nec auctor lacus ultrices. 
                                </div>
                                <br/> */}
                                <div className='sub-cat'>
                                    <h4>Photography & Video Services</h4>
                                    <hr/>
                                    Our team of skilled experts is focused on creating delightful experiences that will last a lifetime. We
                                    specialized in documenting memorable occasions like weddings or birthdays, as well as family portraits
                                    and business movies. When it comes to their photo shoot or video production session, we recognize
                                    how crucial it is that each client's specific demands are addressed, which is why we always take
                                    additional effort to tailor our services to each customer's unique vision and aesthetic preferences. Every
                                    step of our process, from choosing the ideal location to post-production editing, has been especially
                                    designed with you in mind.
                                </div>
                                <br/>
                            </div>
                        </Col>
                        <Col>
                            <div className='ser-img'>
                                <img src="images/events.svg" className='img-fluid animated' alt='service'/>
                            </div>
                        </Col>
                        
                    </Row>
                </Container>
            </div>
            </div>
        </div>
        
    )
}

export default Services;