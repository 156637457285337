import React from 'react';
import '../styles/serviceSection.css';
import Cards from '../components/cards';

function ServiceSection() {
  return (
    <div className='cards'>
      <div className='cards-section'>
        <h1 className='service-header'>Our Services</h1>
        <div className='cards__container'>
          <div className='cards__wrapper'>
            <ul className='cards-items'>
              <Cards
                src='images/marketing-icon.svg'
                text='Digital Marketing'
                path=''
                txt='Digital marketing refers to the use of various online channels and technologies to promote products or services and connect with potential customers.'
              />
              <Cards
                src='images/video-icon.svg'
                text='Video Production'
                path=''
                txt='Video production involves the process of planning, filming, and editing video content for various purposes, such as marketing, entertainment, or education.'
              />
              <Cards
                src='images/tech-icon.svg'
                text='Tech Support'
                path=''
                txt='Tech support involves providing technical assistance and troubleshooting for software, and other technology-related issues.'
              />
              <Cards
                src='images/events-icon.svg'
                text='Events & Activation'
                path=''
                txt='Events and activations involve creating memorable experiences and interactions between brands and consumers through live events, sponsorships, promotions, and other marketing activities.'
              />
            </ul>   
          </div>
        </div>
      </div>
    </div>
  );
}

export default ServiceSection;
