import axios from 'axios';

export const SendEmail = async ({ user, email, phone, text, setSend,}) => {
  try {
    const datas = { user, email, phone, text };
    let res = await axios.post(`http://localhost:5000/send`, datas);
    if (res) {
      setSend(res.data);
    }
  } catch (error) {
    alert(error.response.data.message); 
  }
};