import React from 'react';
import { Link } from 'react-router-dom';

function Video(props) {
  return (
    <>

      

      <div className="container video-item">
      <br/>

        <Link className='video-item-link col-md col-sm' to={props.path}>
          <figure className='video-item-pic'>
            <img className='video-item-img' alt='work' src={props.src} width="100%"/>
              </figure>
            <div className='content'>
              <h5 className='title'>{props.text}</h5>
              <span className='video-details'>{props.det}</span>
            </div>
          </Link>
        </div> 
    </>
  );
}

export default Video;