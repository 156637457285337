import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "../../styles/workpage.css";
import { Container } from "react-bootstrap";
import { TabTitle } from "../titles";

function Shoishob() {
  TabTitle("Shoishob");
  return (
    <div className="cards">
      <Container>
        <img
          src="videos/shoishob.png"
          className="shoishob-img img-thumbnail"
          alt="Responsive"
        />
      </Container>
    </div>
  );
}

export default Shoishob;
