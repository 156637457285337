import React from "react";
import "../styles/main.css";
import Image from "../assets/images/work.jpg";
import { TabTitle } from "../components/titles";
import VideoGallery from "../components/videoGallery";

function Work() {
  TabTitle("Our Work");
  return (
    <div className="work-page">
      <div className="work">
        <img src={Image} className="header-img" alt="header" />
        <h1 className="work-header">Our Work</h1>
      </div>

      <div className="video-cards">
        <VideoGallery />
      </div>
    </div>
  );
}

export default Work;
